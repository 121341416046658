$mol_pick $mol_pop
	event *
		^
		keydown?event <=> keydown?event null
	Anchor <= Trigger $mol_check
		minimal_width 40
		minimal_height 40
		enabled <= trigger_enabled true
		checked? <=> showed?
		clicks? <=> clicks? null
		sub <= trigger_content /$mol_view_content
			<= title
		hint <= hint \
