$optimade_cifplayer_app $mol_drop
	receive? <=> drop_file? null
	Sub <= Book $mol_book2
		plugins /
			<= Theme $mol_theme_auto
		pages <= pages
		Placeholder null
	pages /
		<= Menu $mol_page
			title \Crystal structure web-viewer
			tools /
				<= Source $mol_link_iconed
					uri \https://github.com/tilde-lab/cifplayer
					title \
				<= Lights $mol_lights_toggle
			body / <= Body $mol_view sub /
				<= Upload $mol_button_open
					Icon => Upload_icon
					Native => Upload_native
					files? <=> files_read? null
					sub /
						<= Upload_icon
						<= Upload_native
						\Choose file
				<= Data_text $mol_textarea
					value? <=> data_str?
					hint \or paste here...
		<= Player $optimade_cifplayer_player
			data <= data_str? \
			vibrate? => vibrate?
			unvibrate => unvibrate
			fullscreen false
			light_theme_auto null
	Start $mol_view
		sub /
			<= Start_message $mol_text
				text \Choose or paste a CIF, POSCAR, or Optimade file. No remote server is used.
			<= Paste_example $mol_button_minor
				title \Show example
				click? <=> paste_example? null
