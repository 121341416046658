$mol_button_open $mol_button_minor
	sub /
		<= Icon $mol_icon_upload
		<= Native $mol_button_open_native
			files?next <=> files?next /
			accept <= accept \
			multiple <= multiple true

$mol_button_open_native $mol_view
	dom_name \input
	files?next /
	attr *
		type \file
		accept <= accept \
		multiple <= multiple true
	event *
		change?next <=> picked?next null
