$mol_expander $mol_list
	rows /
		<= Label $mol_view
			sub /
				<= Trigger $mol_check_expand
					checked? <=> expanded? false
					expandable <= expandable true
					label <= label /
						<= title
				<= Tools null
		<= Content $mol_list
			rows <= content /
