namespace $ {
	
	export const $optimade_cifplayer_theme = $mol_style_prop( 
		'optimade_cifplayer_theme',
		[
			'error',
			'warning',
		] as const
	)

}
