$mol_check_list $mol_view
	dictionary *
	Option* $mol_check
		checked? <=> option_checked*? false
		label <= option_label* /
			<= option_title* \
		enabled <= option_enabled* <= enabled true
		hint <= option_hint* \
		minimal_height 24
	options *
	keys /string
	sub <= items /$mol_check
