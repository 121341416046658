namespace $.$$ {

	$mol_style_define( $optimade_cifplayer_lib_three_view, {

		flex: {
			grow: 1,
			basis: '20rem',
		},

	} )

}
