$mol_drop $mol_ghost
	enabled? true
	event *
		dragenter?event <=> enter?event null
		dragover?event <=> move?event null
		dragleave?event <=> leave?event null
		drop?event <=> drop?event null
	attr * mol_drop_status <= status? \ready
	adopt?transfer *
	receive?transfer null
	allow /
		\copy
		\move
		\link
